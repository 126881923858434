* {
  font-family: 'Montserrat', sans-serif !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

ion-button {
  --border-radius: 4px;
  // --box-shadow: none;
  // letter-spacing: 3px;
  text-transform: none;
  font-size: 12px;
}

ion-card {
  box-shadow: 0 2px 16px 0 rgb(220 230 235 / 50%);
}

.content-header {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 4rem;
  ion-img {
    width: 120px;
  }
  ion-text {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-left: 15px;
  }
}

.form-item {
  ion-item:first-child {
    --background: #f4f5f5;
    color: var(--ion-color-primary);
    margin-bottom: 1rem;
    border: 2px solid transparent;
    ion-label {
      margin-right: 10px;
    }
  }
}

.section-label {
  font-size: 18px;
  padding-bottom: 1rem !important;
  display: block;
  font-weight: 500;
  margin: 0;
  color: var(--ion-color-primary);
}

.app.container {
  max-width: 750px;
  // padding: 0;

  &.wider {
    max-width: 900px;
  }
}
