// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** default font **/
  --ion-font-family: 'Montserrat', Arial, sans-serif;

  --ion-color-grey: #323e55;

  // --ion-background-color: var(--ion-color-light);

  // form validation err
  --ion-color-form-validation: #df3030;

  /** text-color **/
  --ion-text-color: #1d1e22;
  --ion-text-color-primary: var(--ion-text-color);
  --ion-text-color-secondary: #313236;
  --ion-text-color-tertiary: #6f6f72;

  /** primary **/
	--ion-color-primary: #31406D;
	--ion-color-primary-rgb: 49,64,109;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #2b3860;
	--ion-color-primary-tint: #46537c;

	--ion-color-secondary: #FF914D;
	--ion-color-secondary-rgb: 255,145,77;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #e08044;
	--ion-color-secondary-tint: #ff9c5f;

  --ion-color-tertiary: #262727;
  --ion-color-tertiary-rgb: 38,39,39;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #212222;
  --ion-color-tertiary-tint: #3c3d3d;

  /** success **/
  --ion-color-success: #52c681;
  --ion-color-success-rgb: 82, 198, 129;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #48ae72;
  --ion-color-success-tint: #63cc8e;

  /** warning **/
  --ion-color-warning: #F4B73E;
  --ion-color-warning-rgb: 244, 183, 62;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d7a137;
  --ion-color-warning-tint: #f5be51;

  /** danger **/
  --ion-color-danger: #e8502f;
  --ion-color-danger-rgb: 232, 80, 47;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cc4629;
  --ion-color-danger-tint: #ea6244;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #6f6f72;
  --ion-color-medium-rgb: 111, 111, 114;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #626264;
  --ion-color-medium-tint: #7d7d80;

  /** light **/
  --ion-color-light: #f4f5f5;
  --ion-color-light-rgb: 244, 245, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8d8;
  --ion-color-light-tint: #f5f6f6;
}

/* Grey 2 */
:root {
  --ion-color-grey-two: #E0E6F0;
  --ion-color-grey-two-rgb: 224, 230, 240;
  --ion-color-grey-two-contrast: #000000;
  --ion-color-grey-two-contrast-rgb: 0, 0, 0;
  --ion-color-grey-two-shade: #c5cad3;
  --ion-color-grey-two-tint: #e3e9f2;
}

.ion-color-grey-two {
  --ion-color-base: var(--ion-color-grey-two);
  --ion-color-base-rgb: var(--ion-color-grey-two-rgb);
  --ion-color-contrast: var(--ion-color-grey-two-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-two-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-two-shade);
  --ion-color-tint: var(--ion-color-grey-two-tint);
}

/* Grey 3 */
:root {
  --ion-color-grey-three: #BFC6D4;
  --ion-color-grey-three-rgb: 183, 186, 189;
  --ion-color-grey-three-contrast: #000000;
  --ion-color-grey-three-contrast-rgb: 0, 0, 0;
  --ion-color-grey-three-shade: #a1a4a6;
  --ion-color-grey-three-tint: #bec1c4;
}

.ion-color-grey-three {
  --ion-color-base: var(--ion-color-grey-three);
  --ion-color-base-rgb: var(--ion-color-grey-three-rgb);
  --ion-color-contrast: var(--ion-color-grey-three-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-three-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-three-shade);
  --ion-color-tint: var(--ion-color-grey-three-tint);
}

/* Grey 4 */
:root {
  --ion-color-grey-four: #7c8188;
  --ion-color-grey-four-rgb: 124, 129, 136;
  --ion-color-grey-four-contrast: #000000;
  --ion-color-grey-four-contrast-rgb: 0, 0, 0;
  --ion-color-grey-four-shade: #6d7278;
  --ion-color-grey-four-tint: #898e94;
}

.ion-color-grey-four {
  --ion-color-base: var(--ion-color-grey-four);
  --ion-color-base-rgb: var(--ion-color-grey-four-rgb);
  --ion-color-contrast: var(--ion-color-grey-four-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-four-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-four-shade);
  --ion-color-tint: var(--ion-color-grey-four-tint);
}

/*
* Grey 6
*/
:root {
  --ion-color-grey-six: #828b9a;
  --ion-color-grey-six-rgb: 130, 139, 154;
  --ion-color-grey-six-contrast: #000000;
  --ion-color-grey-six-contrast-rgb: 0, 0, 0;
  --ion-color-grey-six-shade: #727a88;
  --ion-color-grey-six-tint: #8f97a4;
}

.ion-color-grey-six {
  --ion-color-base: var(--ion-color-grey-six);
  --ion-color-base-rgb: var(--ion-color-grey-six-rgb);
  --ion-color-contrast: var(--ion-color-grey-six-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-six-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-six-shade);
  --ion-color-tint: var(--ion-color-grey-six-tint);
}

/*
* Grey 8
*/
:root {
  --ion-color-grey-eight: #323e55;
  --ion-color-grey-eight-rgb: 50, 62, 85;
  --ion-color-grey-eight-contrast: #ffffff;
  --ion-color-grey-eight-contrast-rgb: 255, 255, 255;
  --ion-color-grey-eight-shade: #2c374b;
  --ion-color-grey-eight-tint: #475166;
}

.ion-color-grey-eight {
  --ion-color-base: var(--ion-color-grey-eight);
  --ion-color-base-rgb: var(--ion-color-grey-eight-rgb);
  --ion-color-contrast: var(--ion-color-grey-eight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-eight-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-eight-shade);
  --ion-color-tint: var(--ion-color-grey-eight-tint);
}

/* Grey 9 */
:root {
  --ion-color-grey-nine: #141d2f;
  --ion-color-grey-nine-rgb: 20, 29, 47;
  --ion-color-grey-nine-contrast: #ffffff;
  --ion-color-grey-nine-contrast-rgb: 255, 255, 255;
  --ion-color-grey-nine-shade: #121a29;
  --ion-color-grey-nine-tint: #2c3444;
}

.ion-color-grey-nine {
  --ion-color-base: var(--ion-color-grey-nine);
  --ion-color-base-rgb: var(--ion-color-grey-nine-rgb);
  --ion-color-contrast: var(--ion-color-grey-nine-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-nine-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-nine-shade);
  --ion-color-tint: var(--ion-color-grey-nine-tint);
}

/*
* Card color: 1
*/
:root {
  --ion-color-card-1: linear-gradient(247.09deg, #189e5e 37.85%, #28ac6d 64.36%);
  --ion-color-card-1-rgb: 29, 160, 97;
  --ion-color-card-1-contrast: #ffffff;
  --ion-color-card-1-contrast-rgb: 255, 255, 255;
  --ion-color-card-1-shade: #1a8d55;
  --ion-color-card-1-tint: #34aa71;
}

.ion-color-card-1 {
  --ion-color-base: var(--ion-color-card-1);
  --ion-color-base-rgb: var(--ion-color-card-1-rgb);
  --ion-color-contrast: var(--ion-color-card-1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-card-1-contrast-rgb);
  --ion-color-shade: var(--ion-color-card-1-shade);
  --ion-color-tint: var(--ion-color-card-1-tint);
}

/*
* Card color: 2
*/
:root {
  --ion-color-card-2: #0c7241;
  --ion-color-card-2-rgb: 12, 114, 65;
  --ion-color-card-2-contrast: #ffffff;
  --ion-color-card-2-contrast-rgb: 255, 255, 255;
  --ion-color-card-2-shade: #0b6439;
  --ion-color-card-2-tint: #248054;
}

.ion-color-card-2 {
  --ion-color-base: var(--ion-color-card-2);
  --ion-color-base-rgb: var(--ion-color-card-2-rgb);
  --ion-color-contrast: var(--ion-color-card-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-card-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-card-2-shade);
  --ion-color-tint: var(--ion-color-card-2-tint);
}

// white btn
:root {
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #323e55;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

// Product Type buttons
// light-green
:root {
  --ion-color-light-green: #51bd89;
  --ion-color-light-green-rgb: 81, 189, 137;
  --ion-color-light-green-contrast: #000000;
  --ion-color-light-green-contrast-rgb: 0, 0, 0;
  --ion-color-light-green-shade: #47a679;
  --ion-color-light-green-tint: #62c495;
}

.ion-color-light-green {
  --ion-color-base: var(--ion-color-light-green);
  --ion-color-base-rgb: var(--ion-color-light-green-rgb);
  --ion-color-contrast: var(--ion-color-light-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-green-shade);
  --ion-color-tint: var(--ion-color-light-green-tint);
}

// light-green variation 2
:root {
  --ion-color-light-green-2: #51bd89;
  --ion-color-light-green-2-rgb: 81, 189, 137;
  --ion-color-light-green-2-contrast: #ffffff;
  --ion-color-light-green-2-contrast-rgb: 255, 255, 255;
  --ion-color-light-green-2-shade: #47a679;
  --ion-color-light-green-2-tint: #62c495;
}

.ion-color-light-green-2 {
  --ion-color-base: var(--ion-color-light-green-2);
  --ion-color-base-rgb: var(--ion-color-light-green-2-rgb);
  --ion-color-contrast: var(--ion-color-light-green-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-green-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-green-2-shade);
  --ion-color-tint: var(--ion-color-light-green-2-tint);
}

// light-blue
:root {
  --ion-color-light-blue: #7aa7eb;
  --ion-color-light-blue-rgb: 122, 167, 235;
  --ion-color-light-blue-contrast: #000000;
  --ion-color-light-blue-contrast-rgb: 0, 0, 0;
  --ion-color-light-blue-shade: #6b93cf;
  --ion-color-light-blue-tint: #87b0ed;
}

.ion-color-light-blue {
  --ion-color-base: var(--ion-color-light-blue);
  --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue-shade);
  --ion-color-tint: var(--ion-color-light-blue-tint);
}

// light-purple
:root {
  --ion-color-light-purple: #8889b9;
  --ion-color-light-purple-rgb: 136, 137, 185;
  --ion-color-light-purple-contrast: #000000;
  --ion-color-light-purple-contrast-rgb: 0, 0, 0;
  --ion-color-light-purple-shade: #7879a3;
  --ion-color-light-purple-tint: #9495c0;
}

.ion-color-light-purple {
  --ion-color-base: var(--ion-color-light-purple);
  --ion-color-base-rgb: var(--ion-color-light-purple-rgb);
  --ion-color-contrast: var(--ion-color-light-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-purple-shade);
  --ion-color-tint: var(--ion-color-light-purple-tint);
}

//light-brown
:root {
  --ion-color-light-brown: #f2a818;
  --ion-color-light-brown-rgb: 242, 168, 24;
  --ion-color-light-brown-contrast: #000000;
  --ion-color-light-brown-contrast-rgb: 0, 0, 0;
  --ion-color-light-brown-shade: #d59415;
  --ion-color-light-brown-tint: #f3b12f;
}

.ion-color-light-brown {
  --ion-color-base: var(--ion-color-light-brown);
  --ion-color-base-rgb: var(--ion-color-light-brown-rgb);
  --ion-color-contrast: var(--ion-color-light-brown-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-brown-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-brown-shade);
  --ion-color-tint: var(--ion-color-light-brown-tint);
}

// light-yellow
:root {
  --ion-color-light-yellow: #ffd541;
  --ion-color-light-yellow-rgb: 255, 213, 65;
  --ion-color-light-yellow-contrast: #000000;
  --ion-color-light-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-light-yellow-shade: #e0bb39;
  --ion-color-light-yellow-tint: #ffd954;
}

.ion-color-light-yellow {
  --ion-color-base: var(--ion-color-light-yellow);
  --ion-color-base-rgb: var(--ion-color-light-yellow-rgb);
  --ion-color-contrast: var(--ion-color-light-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-yellow-shade);
  --ion-color-tint: var(--ion-color-light-yellow-tint);
}

// colors for bill rating
:root {
  --ion-color-rating-bad: #eb7a7a;
  --ion-color-rating-bad-rgb: 235, 122, 122;
  --ion-color-rating-bad-contrast: #000000;
  --ion-color-rating-bad-contrast-rgb: 0, 0, 0;
  --ion-color-rating-bad-shade: #cf6b6b;
  --ion-color-rating-bad-tint: #ed8787;
}

.ion-color-rating-bad {
  --ion-color-base: var(--ion-color-rating-bad);
  --ion-color-base-rgb: var(--ion-color-rating-bad-rgb);
  --ion-color-contrast: var(--ion-color-rating-bad-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rating-bad-contrast-rgb);
  --ion-color-shade: var(--ion-color-rating-bad-shade);
  --ion-color-tint: var(--ion-color-rating-bad-tint);
}

:root {
  --ion-color-rating-bad: #eb7a7a;
  --ion-color-rating-bad-rgb: 235, 122, 122;
  --ion-color-rating-bad-contrast: #000000;
  --ion-color-rating-bad-contrast-rgb: 0, 0, 0;
  --ion-color-rating-bad-shade: #cf6b6b;
  --ion-color-rating-bad-tint: #ed8787;
}

.ion-color-rating-bad {
  --ion-color-base: var(--ion-color-rating-bad);
  --ion-color-base-rgb: var(--ion-color-rating-bad-rgb);
  --ion-color-contrast: var(--ion-color-rating-bad-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rating-bad-contrast-rgb);
  --ion-color-shade: var(--ion-color-rating-bad-shade);
  --ion-color-tint: var(--ion-color-rating-bad-tint);
}

:root {
  --ion-color-rating-ordinary: #fadb9e;
  --ion-color-rating-ordinary-rgb: 250, 219, 158;
  --ion-color-rating-ordinary-contrast: #000000;
  --ion-color-rating-ordinary-contrast-rgb: 0, 0, 0;
  --ion-color-rating-ordinary-shade: #dcc18b;
  --ion-color-rating-ordinary-tint: #fbdfa8;
}

.ion-color-rating-ordinary {
  --ion-color-base: var(--ion-color-rating-ordinary);
  --ion-color-base-rgb: var(--ion-color-rating-ordinary-rgb);
  --ion-color-contrast: var(--ion-color-rating-ordinary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rating-ordinary-contrast-rgb);
  --ion-color-shade: var(--ion-color-rating-ordinary-shade);
  --ion-color-tint: var(--ion-color-rating-ordinary-tint);
}

:root {
  --ion-color-rating-good: #51bd89;
  --ion-color-rating-good-rgb: 81, 189, 137;
  --ion-color-rating-good-contrast: #000000;
  --ion-color-rating-good-contrast-rgb: 0, 0, 0;
  --ion-color-rating-good-shade: #47a679;
  --ion-color-rating-good-tint: #62c495;
}

.ion-color-rating-good {
  --ion-color-base: var(--ion-color-rating-good);
  --ion-color-base-rgb: var(--ion-color-rating-good-rgb);
  --ion-color-contrast: var(--ion-color-rating-good-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rating-good-contrast-rgb);
  --ion-color-shade: var(--ion-color-rating-good-shade);
  --ion-color-tint: var(--ion-color-rating-good-tint);
}

:root {
  --ion-color-rating-excellent: #0c7241;
  --ion-color-rating-excellent-rgb: 12, 114, 65;
  --ion-color-rating-excellent-contrast: #ffffff;
  --ion-color-rating-excellent-contrast-rgb: 255, 255, 255;
  --ion-color-rating-excellent-shade: #0b6439;
  --ion-color-rating-excellent-tint: #248054;
}

.ion-color-rating-excellent {
  --ion-color-base: var(--ion-color-rating-excellent);
  --ion-color-base-rgb: var(--ion-color-rating-excellent-rgb);
  --ion-color-contrast: var(--ion-color-rating-excellent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rating-excellent-contrast-rgb);
  --ion-color-shade: var(--ion-color-rating-excellent-shade);
  --ion-color-tint: var(--ion-color-rating-excellent-tint);
}